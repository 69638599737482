<template>
	<div class="page">
		<div class="container">
            <div style="width:21cm; height:29.7cm">
				`<img style=" height:1200px" src=${obj.print_file}>`
			</div>			

			<div class="box">
				<div class="box1">
					<div class="item" @click="go('/FileUpload')">
						<div class="icon"><i class='iconfont icon-shangchuan'></i></div>
						<div>文件上传</div>
					</div>
				</div>
				<div class="box2">
					<div class="item" @click="go('/MyCloud')">
						<i class='iconfont icon-renwu'></i> 打印任务
					</div>
					<div class="item" @click="go('/MyCloud')"><i class='iconfont icon-lishi'></i> 历史打印</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Home',

		data() {
			return {
				code: require("@/assets/images/code.png")
			}
		},
		methods: {
			go(url) {
				this.$router.push({
					path: url,
				})
			},
			back() {
				console.log(11)
				this.$router.back();
			}
		}
	}
</script>

<style lang="scss" scoped>
	.box {
		height:50vh;
		width: 1000px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		margin-top: 10vh;

		.box1 {
			display: flex;
			flex: 1;
			flex-direction: column;
			margin-right: 15px;

			.item {
				flex: 1;
				background-color: rgba(24, 227, 179, 100);
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				font-size: 50px;
				color: #fff;
				text-align: center;
				cursor: pointer;

				i {
					font-size: 150px;
					
				}

				div {
					width: 100%;
				}

				.icon {
					display: flex;
					justify-content: center;
					align-items: flex-end;
				}


			}

		}

		.box2 {
			flex: 1;
			display: flex;
			flex-direction: column;

			.item {
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 40px;
				color: #fff;
				font-weight: bold;

				cursor: pointer;
				background-color: rgba(68, 227, 24, 100);

				i {
					font-size: 150px;
					margin-right:15px;
				}

				&:nth-child(1) {
					margin-bottom: 15px;
				}

				&:nth-child(2) {
					background-color: rgba(89, 221, 241, 100);
				}


			}
		}
	}

	.top {
		margin-top: -15px;
		height: 30px;
		color: #fff;
		
	}


	.left {
		float: left;
	}

	.right {
		float: right;
	}
</style>
